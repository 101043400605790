<template>
    <span
        :class="classes"
        :style="styles"
        @click="$emit('click')"
    >
        <component
            v-if="provider"
            :is="provider.component"
            :value="provider.value"
            :fullValue="value"

            :size="size"
            :light="light"
            :inactive="inactive"
            :color="color"
        ></component>
        <span v-else :title="value">�</span>

        <div v-if="$slots.default" class="contents-slot">
            <slot></slot>
        </div>
    </span>
</template>

<script>
const ProviderGoogle = () => import('./Provider/Google.vue')
const ProviderImage = () => import('./Provider/Image.vue')

const providers = {
    g: "ProviderGoogle",
    http: "ProviderImage",
    https: "ProviderImage"
}

export default {
    name: 'phi-icon',

    components: {
        ProviderGoogle,
        ProviderImage
    },

    props: {
        value: {
            type: String,
            required: false,
            default: '�'
        },

        size: {
            type: String,
            required: false,
            default: '18' // Expected values are: 18, 24, 36, 48 (https://google.github.io/material-design-icons/)
        },

        color: {
            type: String,
            required: false,
            default: null
        },

        light: {
            type: Boolean,
            required: false,
            default: false
        },

        inactive: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        classes() {
            return [
                'phi-icon',
                this.size ? `--size-${this.size}` : null,
                this.light ? '--light' : '--dark',
                this.inactive ? '--inactive' : null
            ];
        },

        styles() {
            return this.color ? {color: this.color} : null;
        },

        provider() {
            if (!this.value || typeof this.value.split == 'undefined') {
                return null;
            }

            let parts = this.value.split(':', 2);

            if (typeof providers[parts[0]] == 'undefined') {
                return null;
            }

            return {
                key: parts[0],
                component: providers[parts[0]],
                value: parts[1]
            };
        },

        cssColor() {
            return this.reactiveEl ? window.getComputedStyle(this.reactiveEl).color : null;
        }
    },

    data() {
        return {
            reactiveEl: null
        };
    },

    mounted() {
        this.reactiveEl = this.$el;
    }
}
</script>


<style lang="scss">
.phi-icon {
    display: inline-flex;
    align-items: center;

    font-size: 24px; // material icon's default size

    &.--size-18 { font-size: 18px; }
    &.--size-24 { font-size: 24px; }
    &.--size-36 { font-size: 36px; }
    &.--size-48 { font-size: 48px; }

    &.--light {
        color: rgba(255, 255, 255, 1);
    }

    &.--light.--inactive {
        opacity: .7;
    }

    &.--dark.--inactive {
        opacity: .3;
    }

    .contents-slot {
        padding-left: 4px;
        font-size: auto;
        white-space: nowrap;
    }


  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>